import React, { useState, useEffect } from "react";
import { useAddress, useContract, ConnectWallet, darkTheme, useBalance, Token } from "@thirdweb-dev/react";
import { ethers, BigNumber } from "ethers";
import "../styles/BlubStaking.css";
import { Link } from "react-router-dom";
import { Token as ERC20Token } from "@thirdweb-dev/sdk";

// Import new button images
import Button1Static from "../styles/images/new/Button1Static.png";
import Button1Hover from "../styles/images/new/Button1.gif";
import Button2Static from "../styles/images/new/Button2Static.png";
import Button2Hover from "../styles/images/new/Button2.gif";
import NBGMHover from "../styles/images/new/NBGM.gif";
import NBGHover from "../styles/images/new/NBG.gif";

const HeresyContractAddress = "0x432d38F83a50EC77C409D086e97448794cf76dCF";
const BlubContractAddress = "0x0f669808d88B2b0b3D23214DCD2a1cc6A8B1B5cd";
const StakingContractAddress = "0x5946135e229b068c10d8abfe7D1CA2832139Ecd2";

const BlubStaking: React.FC = () => {
  const address = useAddress();
  const [stakeAmount, setStakeAmount] = useState<string>("");
  const [withdrawAmount, setWithdrawAmount] = useState<string>("");
  const [heresyBalance, setHeresyBalance] = useState<string>("0");
  const [stakedAmount, setStakedAmount] = useState<string>("0");
  const [rewards, setRewards] = useState<string>("0");
  const [heresyAllowance, setHeresyAllowance] = useState<BigNumber>(BigNumber.from(0));
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [transactionPending, setTransactionPending] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState<string | null>(null);

  // Add new state for button hover
  const [stakeButtonHover, setStakeButtonHover] = useState(false);
  const [withdrawButtonHover, setWithdrawButtonHover] = useState(false);
  const [claimButtonHover, setClaimButtonHover] = useState(false);

  // Initialize contracts
  const { contract: heresyContract } = useContract(
    HeresyContractAddress,
    "token"
  ) as { contract: ERC20Token | undefined };

  const { contract: blubContract } = useContract(
    BlubContractAddress,
    "token"
  ) as { contract: ERC20Token | undefined };

  const { contract: stakingContract } = useContract(
    StakingContractAddress,
    "custom"
  );

  // Get HERESY token balance
  const { data: heresyTokenBalance } = useBalance(HeresyContractAddress);

  // Load staking data
  useEffect(() => {
    if (address) {
      refreshAllData();
    }
  }, [address, heresyTokenBalance]);

  const refreshAllData = async () => {
    if (!address) return;

    try {
      setIsLoading(true);
      await checkHeresyAllowance();
      if (heresyTokenBalance?.value) {
        setHeresyBalance(ethers.utils.formatUnits(heresyTokenBalance.value.toString(), 18));
      }
      await loadStakeInfo();
    } catch (error) {
      console.error("Error refreshing data:", error);
      setErrorMessage("Failed to load staking data");
    } finally {
      setIsLoading(false);
    }
  };

  const checkHeresyAllowance = async () => {
    if (!heresyContract || !address) return;

    try {
      const allowance = await heresyContract.call(
        "allowance",
        [address, StakingContractAddress]
      );
      setHeresyAllowance(ethers.BigNumber.from(allowance.toString()));
    } catch (error) {
      console.error("Error checking HERESY allowance:", error);
    }
  };

  const loadStakeInfo = async () => {
    if (!stakingContract || !address) return;

    try {
      const stakeInfo = await stakingContract.call("getStakeInfo", [address]);
      setStakedAmount(ethers.utils.formatUnits(stakeInfo._tokensStaked.toString(), 18));
      setRewards(ethers.utils.formatUnits(stakeInfo._rewards.toString(), 18));
    } catch (error) {
      console.error("Error loading stake info:", error);
    }
  };

  const handleStake = async () => {
    if (!stakingContract || !heresyContract || !address || !stakeAmount || parseFloat(stakeAmount) <= 0) {
      setErrorMessage("Please enter a valid amount to stake");
      return;
    }

    try {
      setIsLoading(true);
      setErrorMessage(null);
      setTransactionPending(true);
      setTransactionStatus("Preparing transaction...");
      
      const amountToStake = ethers.utils.parseUnits(stakeAmount, 18);
      
      // Check if we need approval
      if (heresyAllowance.lt(amountToStake)) {
        setTransactionStatus("Requesting approval...");
        console.log("Approving HERESY tokens for staking...");
        
        // Approve max uint256 to avoid frequent approvals
        const maxApproval = ethers.constants.MaxUint256;
        
        // Set a timeout to prevent UI from freezing indefinitely
        const approvalPromise = heresyContract.call("approve", [StakingContractAddress, maxApproval]);
        const timeoutPromise = new Promise((_, reject) => 
          setTimeout(() => reject(new Error("Approval transaction timed out")), 60000)
        );
        
        try {
          await Promise.race([approvalPromise, timeoutPromise]);
          setTransactionStatus("Approval confirmed. Checking allowance...");
          await checkHeresyAllowance();
        } catch (error) {
          console.error("Approval error or timeout:", error);
          setErrorMessage("Approval failed or timed out. Please try again.");
          setTransactionPending(false);
          setTransactionStatus(null);
          setIsLoading(false);
          return;
        }
      }
      
      // Stake tokens
      setTransactionStatus("Staking HERESY tokens...");
      
      // Set a timeout for the staking transaction as well
      const stakingPromise = stakingContract.call("stake", [amountToStake.toString()]);
      const stakingTimeoutPromise = new Promise((_, reject) => 
        setTimeout(() => reject(new Error("Staking transaction timed out")), 60000)
      );
      
      try {
        await Promise.race([stakingPromise, stakingTimeoutPromise]);
        setTransactionStatus("Transaction successful! Refreshing data...");
      } catch (error) {
        console.error("Staking error or timeout:", error);
        setErrorMessage("Staking failed or timed out. Your transaction might still process. Please check your wallet.");
        setTransactionPending(false);
        setTransactionStatus(null);
        setIsLoading(false);
        return;
      }
      
      // Reset input and refresh data
      setStakeAmount("");
      await refreshAllData();
      setTransactionStatus("Complete!");
      
      // Clear status after a few seconds
      setTimeout(() => {
        setTransactionStatus(null);
        setTransactionPending(false);
      }, 3000);
      
    } catch (error) {
      console.error("Staking error:", error);
      setErrorMessage("Failed to stake tokens");
      setTransactionPending(false);
      setTransactionStatus(null);
    } finally {
      setIsLoading(false);
    }
  };

  const handleWithdraw = async () => {
    if (!stakingContract || !address || !withdrawAmount || parseFloat(withdrawAmount) <= 0) {
      setErrorMessage("Please enter a valid amount to withdraw");
      return;
    }

    try {
      setIsLoading(true);
      setErrorMessage(null);
      setTransactionPending(true);
      setTransactionStatus("Preparing withdrawal...");
      
      const amountToWithdraw = ethers.utils.parseUnits(withdrawAmount, 18);
      
      // Withdraw tokens - with timeout protection
      const withdrawPromise = stakingContract.call("withdraw", [amountToWithdraw.toString()]);
      const withdrawTimeoutPromise = new Promise((_, reject) => 
        setTimeout(() => reject(new Error("Withdrawal transaction timed out")), 60000)
      );
      
      try {
        await Promise.race([withdrawPromise, withdrawTimeoutPromise]);
        setTransactionStatus("Withdrawal successful! Refreshing data...");
      } catch (error) {
        console.error("Withdrawal error or timeout:", error);
        setErrorMessage("Withdrawal failed or timed out. Your transaction might still process. Please check your wallet.");
        setTransactionPending(false);
        setTransactionStatus(null);
        setIsLoading(false);
        return;
      }
      
      // Reset input and refresh data
      setWithdrawAmount("");
      await refreshAllData();
      setTransactionStatus("Complete!");
      
      // Clear status after a few seconds
      setTimeout(() => {
        setTransactionStatus(null);
        setTransactionPending(false);
      }, 3000);
      
    } catch (error) {
      console.error("Withdrawal error:", error);
      setErrorMessage("Failed to withdraw tokens");
      setTransactionPending(false);
      setTransactionStatus(null);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClaimRewards = async () => {
    if (!stakingContract || !address) return;

    try {
      setIsLoading(true);
      setErrorMessage(null);
      setTransactionPending(true);
      setTransactionStatus("Preparing to claim rewards...");
      
      // Claim rewards with timeout protection
      const claimPromise = stakingContract.call("claimRewards", []);
      const claimTimeoutPromise = new Promise((_, reject) => 
        setTimeout(() => reject(new Error("Claim transaction timed out")), 60000)
      );
      
      try {
        await Promise.race([claimPromise, claimTimeoutPromise]);
        setTransactionStatus("Claim successful! Refreshing data...");
      } catch (error) {
        console.error("Claim error or timeout:", error);
        setErrorMessage("Claim failed or timed out. Your transaction might still process. Please check your wallet.");
        setTransactionPending(false);
        setTransactionStatus(null);
        setIsLoading(false);
        return;
      }
      
      // Refresh data
      await refreshAllData();
      setTransactionStatus("Complete!");
      
      // Clear status after a few seconds
      setTimeout(() => {
        setTransactionStatus(null);
        setTransactionPending(false);
      }, 3000);
      
    } catch (error) {
      console.error("Reward claim error:", error);
      setErrorMessage("Failed to claim rewards");
      setTransactionPending(false);
      setTransactionStatus(null);
    } finally {
      setIsLoading(false);
    }
  };

  const formatNumber = (value: string) => {
    const num = parseFloat(value);
    if (isNaN(num)) return "0.0000";
    
    if (num > 1000000) {
      return (num / 1000000).toFixed(4) + "M";
    } else if (num > 1000) {
      return (num / 1000).toFixed(4) + "K";
    } else {
      return num.toFixed(4);
    }
  };

  return (
    <div className="main cyberpunk-bg">
      <div className="scanline-effect"></div>
      {transactionPending && (
        <div className="transaction-status-overlay">
          <div className="transaction-status-modal">
            <div className="transaction-spinner"></div>
            <p className="transaction-status-text">{transactionStatus}</p>
            <p className="transaction-note">Blockchain transactions may take some time to complete</p>
          </div>
        </div>
      )}
      
      <div className="staking-container">
        <Link to="/" className="back-link">
          <div className="back-button">
            <span className="fa">← BACK TO HOME</span>
          </div>
        </Link>
        
        <div className="staking-header">
          <h1 className="staking-title fa" data-text="BLUB STAKING">
            <span className="gradient-text-0">BLUB</span>
            <span className="gradient-text-3"> STAKING</span>
          </h1>
        </div>

        <div className="staking-content">
          <div className="staking-card">
            <div className="card-header">
              <h2 className="fa">STAKE $HERESY, EARN $BLUB</h2>
            </div>
            
            {address ? (
              <div className="staking-body">
                <div className="balance-grid">
                  <div className="balance-cell">
                    <div className="balance-wrapper">
                      <div className="balance-label fa">HERESY</div>
                      <div className="balance-label-subtitle fa">BALANCE</div>
                      <div className="balance-value fb">{formatNumber(heresyBalance)}</div>
                    </div>
                  </div>
                  <div className="balance-cell">
                    <div className="balance-wrapper">
                      <div className="balance-label fa">STAKED</div>
                      <div className="balance-label-subtitle fa">HERESY</div>
                      <div className="balance-value fb">{formatNumber(stakedAmount)}</div>
                    </div>
                  </div>
                  <div className="balance-cell">
                    <div className="balance-wrapper">
                      <div className="balance-label fa">CLAIMABLE</div>
                      <div className="balance-label-subtitle fa">BLUB</div>
                      <div className="balance-value fb">{formatNumber(rewards)}</div>
                    </div>
                  </div>
                </div>

                {/* Staking Form */}
                <div className="action-panel">
                  <div className="form-group">
                    <label className="cyberpunk-label fa">AMOUNT TO STAKE</label>
                    <div className="cyber-input-container">
                      <input
                        type="number"
                        value={stakeAmount}
                        onChange={(e) => setStakeAmount(e.target.value)}
                        placeholder="0.0000"
                        className="cyber-input"
                        min="0"
                        step="0.0001"
                      />
                      <button 
                        className="custom-button"
                        onClick={handleStake}
                        disabled={isLoading || !stakeAmount || parseFloat(stakeAmount) <= 0 || parseFloat(stakeAmount) > parseFloat(heresyBalance)}
                        onMouseEnter={() => setStakeButtonHover(true)}
                        onMouseLeave={() => setStakeButtonHover(false)}
                        style={{
                          backgroundImage: `url(${stakeButtonHover ? Button1Hover : Button1Static})`,
                        }}
                      >
                        <span className="button-text fa">
                          {isLoading ? "PROCESSING..." : "STAKE HERESY"}
                        </span>
                      </button>
                    </div>
                  </div>

                  {/* Withdraw Form */}
                  <div className="form-group">
                    <label className="cyberpunk-label fa">AMOUNT TO WITHDRAW</label>
                    <div className="cyber-input-container">
                      <input
                        type="number"
                        value={withdrawAmount}
                        onChange={(e) => setWithdrawAmount(e.target.value)}
                        placeholder="0.0000"
                        className="cyber-input"
                        min="0"
                        step="0.0001"
                      />
                      <button 
                        className="custom-button"
                        onClick={handleWithdraw}
                        disabled={isLoading || !withdrawAmount || parseFloat(withdrawAmount) <= 0 || parseFloat(withdrawAmount) > parseFloat(stakedAmount)}
                        onMouseEnter={() => setWithdrawButtonHover(true)}
                        onMouseLeave={() => setWithdrawButtonHover(false)}
                        style={{
                          backgroundImage: `url(${withdrawButtonHover ? Button2Hover : Button2Static})`,
                        }}
                      >
                        <span className="button-text fa">
                          {isLoading ? "PROCESSING..." : "WITHDRAW HERESY"}
                        </span>
                      </button>
                    </div>
                  </div>

                  {/* Claim Rewards Button */}
                  <div className="form-group centered">
                    <button 
                      className="custom-button claim-button"
                      onClick={handleClaimRewards}
                      disabled={isLoading || parseFloat(rewards) <= 0}
                      onMouseEnter={() => setClaimButtonHover(true)}
                      onMouseLeave={() => setClaimButtonHover(false)}
                      style={{
                        backgroundImage: `url(${claimButtonHover ? NBGMHover : NBGHover})`,
                      }}
                    >
                      <span className="button-text fa">
                        {isLoading ? "PROCESSING..." : "CLAIM BLUB REWARDS"}
                      </span>
                    </button>
                  </div>

                  {errorMessage && (
                    <div className="error-message fb">
                      {errorMessage}
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="connect-wallet-message">
                <p className="fa">CONNECT YOUR WALLET TO STAKE HERESY TOKENS AND EARN BLUB REWARDS</p>
                <div className="connect-wallet-container">
                  <ConnectWallet 
                    className="ccbutton" 
                    style={{ 
                      fontFamily: 'Doctor Glitch',
                      fontWeight: 'normal',
                      fontStyle: 'oblique',
                      color: 'white'
                    }} 
                    theme={darkTheme({ 
                      fontFamily: "Hacked, sans-serif", 
                      colors: { 
                        modalBg: "#002020", 
                        accentText: "cyan" 
                      } 
                    })}
                    btnTitle="CONNECT WALLET"
                    modalTitle="CONNECT YOUR WALLET"
                    modalSize="wide"
                    welcomeScreen={{
                      title: "Welcome to BLUB Staking",
                      subtitle: "Connect your wallet to start staking HERESY tokens and earn BLUB rewards"
                    }}
                    modalTitleIconUrl=""
                    detailsBtn={() => {
                      return <span>View Details</span>;
                    }}
                    hideTestnetFaucet={false}
                    switchToActiveChain={true}
                  />
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="how-it-works">
          <h2 className="section-title fa">HOW IT WORKS</h2>
          <div className="steps-container">
            <div className="step">
              <span className="step-number">1</span> 
              <span className="step-text fa">STAKE YOUR $HERESY TOKENS</span>
            </div>
            <div className="step">
              <span className="step-number">2</span> 
              <span className="step-text fa">EARN $BLUB REWARDS OVER TIME</span>
            </div>
            <div className="step">
              <span className="step-number">3</span> 
              <span className="step-text fa">CLAIM REWARDS ANYTIME</span>
            </div>
            <div className="step">
              <span className="step-number">4</span> 
              <span className="step-text fa">WITHDRAW YOUR STAKED $HERESY WHEN YOU WANT</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlubStaking; 