import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import RoleClaim from './components/RoleClaim';
import BlubStaking from './components/BlubStaking';
import React, { useEffect, useState } from 'react';
import { 
  ConnectWallet, 
  Web3Button,
  useContract,
  useContractRead,
  useAddress,
  useTokenBalance,
  ThirdwebNftMedia,
  darkTheme
} from "@thirdweb-dev/react";
import NFTCard from "./components/NFTCard";
import UnstakedNFTCard from "./components/UnstakedNFTCard";
import "./styles/Home.css";
import { BigNumber, ethers } from "ethers";
// Import React Icons
import { FaDiscord } from 'react-icons/fa';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { FaExchangeAlt } from 'react-icons/fa';
import { FaUser } from 'react-icons/fa';
import { FaCoins } from 'react-icons/fa';
import { FaShieldAlt } from 'react-icons/fa';

// Add a wrapper component for React icons
const IconWrapper = ({ icon }: { icon: React.ReactNode }) => {
  return <>{icon}</>;
};

interface NftBalance {
  tokenId: string;
}

interface PrizeEvent {
  prizeType: number;
  tokenAddress: string;
  amount: string;
  quantity: string;
}

interface PrizeOutcome {
  name: string;
  probability: number;
  prizeType: number;
  tokenAddress: string;
  amount: string | number;
  tokenId: number;
  quantity: number;
  percentage: number;
}

type PrizeGivenEvent = {
  player: string;
  pType: number;
  token: string;
  amt: string;
  tid: number;
  qty: number;
  idx: number;
};

// Add this type definition for contract events
type ContractEvent = {
  eventName: string;
  data: PrizeGivenEvent;
};

// Add these type definitions at the top of your file
interface Log {
  topics: string[];
  data: string;
  transactionHash: string;
  logIndex: number;
  blockNumber: number;
  blockHash: string;
  address: string;
}

interface TransactionReceipt {
  to: string;
  from: string;
  contractAddress: string;
  transactionIndex: number;
  gasUsed: ethers.BigNumber;
  logsBloom: string;
  blockHash: string;
  transactionHash: string;
  logs: Log[];
  blockNumber: number;
  confirmations: number;
  cumulativeGasUsed: ethers.BigNumber;
  effectiveGasPrice: ethers.BigNumber;
  status: number;
  type: number;
  byzantium: boolean;
}

// Add this type for prize names to ensure consistency
type PrizeName = 
  | "Greed's Gift"
  | "Maw's Curse"
  | "Spoils of The Core"
  | "Sakura's Embrace"
  | "Siren's Call"
  | "Wrath's Intervention"
  | "Heretic's Honour";

// Add this at the top of your file with other constants
const AURA_TOKEN = "0x3DB3aa87121d16407cFbbADf3f1c5b519182a399";
const HERESY_TOKEN = "0x432d38F83a50EC77C409D086e97448794cf76dCF";

// Add this before or after the LoadingIndicator component
const ProgressBar: React.FC<{current: number; max: number; label: string}> = ({current, max, label}) => {
  const percentage = Math.min(100, (current / max) * 100);
  return (
    <div className="progress-container">
      <div className="progress-label fb">{label}</div>
      <div className="progress-track">
        <div 
          className="progress-fill" 
          style={{width: `${percentage}%`}}
        ></div>
      </div>
      <div className="progress-text fb">{current} of {max}</div>
    </div>
  );
};

// Add LoadingIndicator component
const LoadingIndicator: React.FC<{isActive: boolean}> = ({isActive}) => {
  if (!isActive) return null;
  return (
    <div className="loading-overlay">
      <div className="loading-spinner">
        <div className="spinner-inner"></div>
      </div>
      <div className="loading-text fb">Transaction in progress...</div>
    </div>
  );
};

// Add a notification component after the other UI components
const Notification: React.FC<{
  message: string;
  type: 'success' | 'error' | 'info';
  isVisible: boolean;
  onClose: () => void;
}> = ({ message, type, isVisible, onClose }) => {
  useEffect(() => {
    if (!isVisible) return;
    
    const timer = setTimeout(() => {
      onClose();
    }, 5000);
    return () => clearTimeout(timer);
  }, [isVisible, onClose]);
  
  if (!isVisible) return null;
  
  return (
    <div className={`notification ${type}-notification`}>
      <div className="notification-content">
        <div className={`notification-icon ${type}-icon`}></div>
        <div className="notification-message">{message}</div>
      </div>
      <button className="notification-close" onClick={onClose}>×</button>
    </div>
  );
};

// Add reward animation component 
const RewardAnimation: React.FC<{amount: string; isVisible: boolean; onComplete: () => void}> = ({
  amount, isVisible, onComplete
}) => {
  const [particles, setParticles] = useState<{id: number; x: number; y: number; size: number; speed: number}[]>([]);
  const [opacity, setOpacity] = useState(0);
  
  useEffect(() => {
    if (!isVisible) return;
    
    // Fade in
    setOpacity(0);
    const fadeInTimer = setTimeout(() => {
      setOpacity(1);
    }, 50);
    
    // Create particles (reduced number and made them smaller)
    const newParticles = Array.from({length: 20}, (_, i) => ({
      id: i,
      x: 50 + Math.random() * 15 - 7.5,  // Center x with some variation
      y: 60 + Math.random() * 10,        // Start a bit higher
      size: 6 + Math.random() * 8,       // Smaller size
      speed: 0.6 + Math.random() * 0.8   // Slower speed
    }));
    
    setParticles(newParticles);
    
    // Clean up animation with fade out
    const fadeOutTimer = setTimeout(() => {
      setOpacity(0);
    }, 2400);
    
    const completeTimer = setTimeout(() => {
      onComplete();
    }, 3000);
    
    return () => {
      clearTimeout(fadeInTimer);
      clearTimeout(fadeOutTimer);
      clearTimeout(completeTimer);
    };
  }, [isVisible, onComplete]);
  
  if (!isVisible) return null;
  
  return (
    <div 
      className="reward-animation-container"
      style={{ 
        opacity: opacity,
        transition: 'opacity 0.6s ease-in-out'
      }}
    >
      <div className="reward-amount">{amount}</div>
      <div className="particles-container">
        {particles.map(particle => (
          <div
            key={particle.id}
            className="particle"
            style={{
              left: `${particle.x}%`,
              bottom: `${particle.y}%`,
              width: `${particle.size}px`,
              height: `${particle.size}px`,
              animationDuration: `${3 / particle.speed}s`
            }}
          />
        ))}
      </div>
    </div>
  );
};

// Add a tooltip component
const Tooltip: React.FC<{text: string; children: React.ReactNode}> = ({text, children}) => {
  return (
    <div className="tooltip-container">
      {children}
      <div className="tooltip-text">{text}</div>
    </div>
  );
};

// Add this before the App component
// Define the shake animation
const shakeAnimation = `
@keyframes shake {
  0% { transform: translate(0, 0) rotate(0deg); }
  10% { transform: translate(-0.25px, -0.25px) rotate(-0.125deg); }
  20% { transform: translate(0.25px, -0.25px) rotate(0.125deg); }
  30% { transform: translate(-0.25px, 0.25px) rotate(-0.0625deg); }
  40% { transform: translate(0.25px, 0.25px) rotate(0.0625deg); }
  50% { transform: translate(-0.25px, -0.25px) rotate(-0.125deg); }
  60% { transform: translate(0.25px, -0.25px) rotate(0.125deg); }
  70% { transform: translate(-0.25px, 0.25px) rotate(-0.0625deg); }
  80% { transform: translate(-0.25px, -0.25px) rotate(0.0625deg); }
  90% { transform: translate(0.25px, -0.25px) rotate(0deg); }
  100% { transform: translate(0, 0) rotate(0deg); }
}

.shake {
  animation: shake 0.4s infinite;
  animation-timing-function: cubic-bezier(.36,.07,.19,.97);
  transform-origin: center;
  perspective: 1000px;
}

/* Add claim rewards button animations */
@keyframes glowPulse {
  0% { box-shadow: 0 0 5px rgba(0, 255, 255, 0.5); }
  50% { box-shadow: 0 0 20px rgba(0, 255, 255, 0.8), 0 0 30px rgba(0, 200, 255, 0.4); }
  100% { box-shadow: 0 0 5px rgba(0, 255, 255, 0.5); }
}

@keyframes buttonClick {
  0% { transform: scale(1); }
  50% { transform: scale(0.95); }
  100% { transform: scale(1); }
}

.claim-rewards-button {
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease !important;
}

.claim-rewards-button:hover {
  animation: glowPulse 1.5s infinite;
  transform: translateY(-2px);
}

.claim-rewards-button:active {
  animation: buttonClick 0.3s ease !important;
}

.claim-rewards-button:hover::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: rgba(255, 255, 255, 0.1);
  transform: rotate(45deg);
  animation: shimmer 2s infinite linear;
  z-index: -1;
}

@keyframes shimmer {
  0% { transform: translateX(-100%) rotate(45deg); }
  100% { transform: translateX(100%) rotate(45deg); }
}

/* UPDATED CYBERPUNK ANIMATIONS */
@keyframes cyberpunkGlow {
  0% { box-shadow: 0 0 5px #00ff99, 0 0 8px #00ffff, 0 0 0 rgba(0, 255, 255, 0); }
  25% { box-shadow: 0 0 15px #ff00ff, 0 0 25px #00ffff, 0 0 5px rgba(0, 255, 255, 0.5); }
  50% { box-shadow: 0 0 25px #00ff99, 0 0 40px #00ffff, 0 0 10px rgba(0, 255, 255, 0.7); }
  75% { box-shadow: 0 0 15px #ff00ff, 0 0 25px #00ffff, 0 0 5px rgba(0, 255, 255, 0.5); }
  100% { box-shadow: 0 0 5px #00ff99, 0 0 8px #00ffff, 0 0 0 rgba(0, 255, 255, 0); }
}

/* Add new pink glow animation for claim rewards button */
@keyframes pinkGlow {
  0% { box-shadow: 0 0 5px #ff69b4, 0 0 10px #ff1493; }
  50% { box-shadow: 0 0 15px #ff69b4, 0 0 25px #ff1493, 0 0 35px rgba(255, 105, 180, 0.5); }
  100% { box-shadow: 0 0 5px #ff69b4, 0 0 10px #ff1493; }
}

@keyframes glitchText {
  0% { text-shadow: 0.05em 0 0 #ff00ff, -0.05em -0.025em 0 #00ffff; }
  25% { text-shadow: -0.05em -0.025em 0 #ff00ff, 0.025em 0.05em 0 #00ffff; }
  50% { text-shadow: 0.025em 0.05em 0 #ff00ff, -0.05em -0.025em 0 #00ffff; }
  75% { text-shadow: -0.05em -0.025em 0 #ff00ff, 0.025em 0.05em 0 #00ffff; }
  100% { text-shadow: 0.05em 0 0 #ff00ff, -0.05em -0.025em 0 #00ffff; }
}

@keyframes borderFlash {
  0% { border-color: #00ff99; }
  25% { border-color: #00ffff; }
  50% { border-color: #ff00ff; }
  75% { border-color: #ffff00; }
  100% { border-color: #00ff99; }
}

@keyframes buttonPulse {
  0% { transform: scale(1); opacity: 1; }
  50% { transform: scale(1.05); opacity: 0.9; }
  100% { transform: scale(1); opacity: 1; }
}

@keyframes glitchClip {
  0% { clip-path: inset(80% 0 0 0); }
  10% { clip-path: inset(10% 0 80% 0); }
  20% { clip-path: inset(80% 0 13% 0); }
  30% { clip-path: inset(10% 0 60% 0); }
  40% { clip-path: inset(50% 0 0% 0); }
  50% { clip-path: inset(0% 0 100% 0); }
  60% { clip-path: inset(100% 0 0% 0); }
  70% { clip-path: inset(10% 0 60% 0); }
  80% { clip-path: inset(80% 0 5% 0); }
  90% { clip-path: inset(5% 0 80% 0); }
  100% { clip-path: inset(80% 0 0 0); }
}

.claim-rewards-button {
  position: relative;
  overflow: hidden;
  transition: all 0.2s ease !important;
  border: 2px solid #ff69b4 !important;
  background-color: rgba(0, 20, 40, 0.7) !important;
  backdrop-filter: blur(4px);
}

.claim-rewards-button:hover {
  animation: buttonPulse 1.5s infinite, pinkGlow 2s infinite !important;
  transform: translateY(-3px) !important;
  filter: contrast(1.2);
  background-color: rgba(0, 30, 60, 0.8) !important;
}

.claim-rewards-button:hover .fb {
  animation: glitchText 2s infinite;
  color: white !important;
}

.claim-rewards-button:active {
  animation: buttonClick 0.2s ease !important;
  transform: scale(0.95) !important;
}

.claim-rewards-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.3), transparent);
  transition: 0.5s;
  z-index: -1;
}

.claim-rewards-button:hover::before {
  left: 100%;
  transition: 0.5s;
}

.claim-rewards-button::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url("data:image/svg+xml,%3Csvg viewBox='0 0 400 400' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%25' height='100%25' filter='url(%23noiseFilter)'/%3E%3C/svg%3E");
  opacity: 0;
  z-index: -1;
  transition: opacity 0.3s ease;
  mix-blend-mode: overlay;
}

.claim-rewards-button:hover::after {
  opacity: 0.2;
  animation: glitchClip 2s infinite linear alternate-reverse;
}
`;

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/roleclaim" element={<RoleClaim />} />
        <Route path="/blubstaking" element={<BlubStaking />} />
      </Routes>
    </Router>
  );
};

const Home: React.FC = () => {
  const address = useAddress();
  const [ownedTokens, setOwnedTokens] = useState<NftBalance[]>([]);
  const [stakedTokens, setStakedTokens] = useState<BigNumber[]>([]);
  const [claimableRewards, setClaimableRewards] = useState<BigNumber>();
  const [isLoading, setIsLoading] = useState(false);
  const [auraAllowance, setAuraAllowance] = useState<BigNumber>(BigNumber.from(0));
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [currentPrize, setCurrentPrize] = useState<string | null>(null);
  const [avaxBalance, setAvaxBalance] = useState<string>("0");
  const [currentVideo, setCurrentVideo] = useState<string | null>(null);
  const [isPlayingVideo, setIsPlayingVideo] = useState(false);
  const [blockConfirmations, setBlockConfirmations] = useState<number>(0);
  const [explorationBlock, setExplorationBlock] = useState<number | null>(null);
  const [isMonitoring, setIsMonitoring] = useState(false);
  const [explorationState, setExplorationState] = useState<'none' | 'exploring' | 'ready-to-claim'>('none');
  const [currentPrizeName, setCurrentPrizeName] = useState<string | null>(null);
  const [needsApproval, setNeedsApproval] = useState<boolean>(true);
  const [userDisplayName, setUserDisplayName] = useState<string | null>(null);
  const [circulatingSupply, setCirculatingSupply] = useState<string>("Loading...");
  const [burnedTokens, setBurnedTokens] = useState<string>("Loading...");
  const [emergencyClaimAvailable, setEmergencyClaimAvailable] = useState(false);
  // Add volume control state
  const [videoVolume, setVideoVolume] = useState<number>(0.7); // Default volume at 70%
  const [isVolumeSliderVisible, setIsVolumeSliderVisible] = useState<boolean>(false);
  // Add video ref
  const videoRef = React.useRef<HTMLVideoElement>(null);
  
  // Add reward animation state
  const [rewardAnimation, setRewardAnimation] = useState({
    isVisible: false,
    amount: "0"
  });

  // Add notification state
  const [notification, setNotification] = useState<{
    message: string;
    type: 'success' | 'error' | 'info';
    isVisible: boolean;
  }>({
    message: '',
    type: 'info',
    isVisible: false
  });

  // Add notification handlers
  const showNotification = (message: string, type: 'success' | 'error' | 'info') => {
    setNotification({
      message,
      type,
      isVisible: true
    });
  };

  const hideNotification = () => {
    setNotification(prev => ({
      ...prev,
      isVisible: false
    }));
  };

  // Updated contract addresses for mainnet
  const distortionsContractAddress = "0x0a337be2ea71e3aea9c82d45b036ac6a6123b6d0";
  const stakingContractAddress = "0x51697170F78136c8d143B0013Cf5B229aDe70757";
  const prizeDistributionContractAddress = "0xd3106799fD6D4bA51AD58A4e77a68fC10f47FF5c";
  const auraContractAddress = "0x3DB3aa87121d16407cFbbADf3f1c5b519182a399";
  const heresyContractAddress = "0x432d38f83a50ec77c409d086e97448794cf76dcf";

  // Initialize contracts with useContract hooks
  const { contract: distortionsContract, isLoading: isDistortionsLoading } = useContract(
    distortionsContractAddress,
    "nft-drop"
  );
  const { contract: stakingContract, isLoading: isStakingLoading } = useContract(
    stakingContractAddress,
    "custom" // Add the correct contract type here if known
  );
  const { contract: prizeDistributionContract, isLoading: isPrizeLoading } = useContract(
    prizeDistributionContractAddress,
    "custom"
  );
  const { contract: auraContract, isLoading: isAuraLoading } = useContract(
    auraContractAddress,
    "token"
  );
  const { contract: heresyContract, isLoading: isHeresyLoading } = useContract(
    heresyContractAddress,
    "token"
  );

  // Initialize role claim contract
  const { contract: roleClaimContract } = useContract(
    "0x5463143F0E79DBaA602AebA78d6c31A433d6F96F",
    "custom"
  );

  // Add useEffect to fetch display name
  useEffect(() => {
    const fetchDisplayName = async () => {
      if (!address || !roleClaimContract) return;
      
      try {
        const name = await roleClaimContract.call("readDisplayName", [address]);
        if (name && name !== "") {
          setUserDisplayName(name);
        }
      } catch (error) {
        console.error("Error fetching display name:", error);
      }
    };

    fetchDisplayName();
  }, [address, roleClaimContract]);

  // Add a new useEffect to monitor contract initialization
  useEffect(() => {
    console.log("Contract initialization status:", {
      distortions: { contract: !!distortionsContract, loading: isDistortionsLoading },
      staking: { contract: !!stakingContract, loading: isStakingLoading },
      prizeDistribution: { contract: !!prizeDistributionContract, loading: isPrizeLoading },
      aura: { contract: !!auraContract, loading: isAuraLoading },
      heresy: { contract: !!heresyContract, loading: isHeresyLoading }
    });

    // Only proceed with data refresh when all contracts are ready
    if (address && 
        distortionsContract && 
        stakingContract && 
        prizeDistributionContract && 
        auraContract && heresyContract &&
        !isDistortionsLoading &&
        !isStakingLoading &&
        !isPrizeLoading &&
        !isAuraLoading &&
        !isHeresyLoading) {
      console.log("All contracts initialized, refreshing data...");
      refreshAllData();
    }
  }, [
    address,
    distortionsContract,
    stakingContract,
    prizeDistributionContract,
    auraContract,
    heresyContract,
    isDistortionsLoading,
    isStakingLoading,
    isPrizeLoading,
    isAuraLoading,
    isHeresyLoading
  ]);

  // Modify the existing address useEffect to only handle initial connection
  useEffect(() => {
    if (address) {
      console.log("Wallet connected:", address);
    }
  }, [address]);

  const isWalletConnected = !!address;
  const hasTokenStaked = stakedTokens.length > 0;
  const { data: auraBalance } = useTokenBalance(auraContract, address);
  const [auraFee, setAuraFee] = useState<BigNumber>();
  const [avaxFee, setAvaxFee] = useState<BigNumber>();
  const [hasExplored, setHasExplored] = useState(false);
  const { data: heresyBalance } = useTokenBalance(heresyContract, address);

  // Add prize outcomes constant
  const PRIZE_OUTCOMES: PrizeOutcome[] = [
    {
        "name": "Greed's Gift",
        "probability": 500,
        "prizeType": 1,
        "tokenAddress": "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
        "amount": "250000000000000000",
        "tokenId": 0,
        "quantity": 0,
        "percentage": 0
    },
    {
        "name": "Maw's Curse",
        "probability": 3000,
        "prizeType": 0,
        "tokenAddress": "0x0000000000000000000000000000000000000000",
        "amount": 0,
        "tokenId": 0,
        "quantity": 0,
        "percentage": 0
    },
    {
        "name": "Spoils of The Core",
        "probability": 1250,
        "prizeType": 4,
        "tokenAddress": "0x0000000000000000000000000000000000000000",
        "amount": 0,
        "tokenId": 0,
        "quantity": 1,
        "percentage": 0
    },
    {
      "name": "Spoils of The Core",
      "probability": 1250,
      "prizeType": 5,
      "tokenAddress": "0x0000000000000000000000000000000000000000",
      "amount": 0,
      "tokenId": 0,
      "quantity": 1,
      "percentage": 0
  },
    {
        "name": "Sakura's Embrace",
        "probability": 500,
        "prizeType": 4,
        "tokenAddress": "0x0000000000000000000000000000000000000000",
        "amount": 0,
        "tokenId": 0,
        "quantity": 2,
        "percentage": 0
    },
    {
        "name": "Siren's Call",
        "probability": 1500,
        "prizeType": 1,
        "tokenAddress": "0x3DB3aa87121d16407cFbbADf3f1c5b519182a399",
        "amount": "500000000000000000000",
        "tokenId": 0,
        "quantity": 0,
        "percentage": 0
    },
    {
        "name": "Wrath's Intervention",
        "probability": 1000,
        "prizeType": 1,
        "tokenAddress": "0x3DB3aa87121d16407cFbbADf3f1c5b519182a399",
        "amount": "750000000000000000000",
        "tokenId": 0,
        "quantity": 0,
        "percentage": 0
    },
    {
        "name": "Heretic's Honour",
        "probability": 1000,
        "prizeType": 6,
        "tokenAddress": "0x0000000000000000000000000000000000000000",
        "amount": 0,
        "tokenId": 0,
        "quantity": 0,
        "percentage": 500
    }
];

  // Add new function to match prize event to outcome
  const matchPrizeToOutcome = (event: PrizeGivenEvent): string | null => {
    console.log("Trying to match prize event:", event);
    
    const matchingPrize = PRIZE_OUTCOMES.find(outcome => {
      console.log("Checking against outcome:", outcome);
      console.log("Type match:", outcome.prizeType === event.pType);
      console.log("Address match:", outcome.tokenAddress.toLowerCase() === event.token.toLowerCase());
      console.log("Amount/Quantity match:", 
        outcome.amount.toString() === event.amt || 
        outcome.quantity === event.qty ||
        (outcome.percentage > 0 && event.pType === 7)
      );
      
      return outcome.prizeType === event.pType &&
        outcome.tokenAddress.toLowerCase() === event.token.toLowerCase() &&
        (outcome.amount.toString() === event.amt || 
         outcome.quantity === event.qty ||
         (outcome.percentage > 0 && event.pType === 7));
    });

    console.log("Matched prize:", matchingPrize);
    return matchingPrize ? matchingPrize.name : null;
  };

  // Add a new function to handle all data refreshes
  const refreshAllData = async () => {
    if (!address) return;
    console.log("Starting refreshAllData with contracts:", {
      hasStakingContract: !!stakingContract,
      hasDistortionsContract: !!distortionsContract,
      hasPrizeDistributionContract: !!prizeDistributionContract,
      hasAuraContract: !!auraContract,
      hasHeresyContract: !!heresyContract,
      currentAddress: address
    });
    
    setIsLoading(true);
    try {
      await Promise.all([
        refreshTokenBalances(),
        loadStakedTokens(),
        loadClaimableRewards(),
        loadFees(),
        checkExplorationStatus(),
        fetchAvaxBalance(),
        calculateSupplies()
      ]);
    } catch (error) {
      console.error("Error in refreshAllData:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Modify the initial useEffect to include a small delay for wallet reconnection
  useEffect(() => {
    if (address) {
      console.log("Address changed, refreshing data...");
      // Initial load
      refreshAllData();
      
      // Add a delayed second refresh to handle wallet reconnection
      const timer = setTimeout(() => {
        refreshAllData();
      }, 1500);

      return () => clearTimeout(timer);
    }
  }, [address]);

  useEffect(() => {
    const checkAuraAllowance = async () => {
      try {
        if (!address || !auraContract || !auraFee) {
          console.log("Missing requirements for allowance check:", {
            hasAddress: !!address,
            hasContract: !!auraContract,
            hasFee: !!auraFee
          });
          return;
        }
        console.log("Checking AURA allowance...");
        const allowance = await auraContract.call("allowance", [address, prizeDistributionContractAddress]);
        console.log("AURA allowance:", allowance.toString());
        console.log("Required AURA fee:", auraFee.toString());

        const allowanceValue = Array.isArray(allowance) ? allowance[0] : allowance;
        setAuraAllowance(allowanceValue);
        // Compare the allowance with the required fee
        setNeedsApproval(allowanceValue.lt(auraFee));
      } catch (error) {
        console.error("Error fetching AURA allowance:", error);
        setAuraAllowance(BigNumber.from(0));
        setNeedsApproval(true);
      }
    };

    if (address && auraContract && auraFee) {
      checkAuraAllowance();
    }
  }, [address, auraContract, auraFee]); // Add auraFee to dependencies

  // Update the refreshTokenBalances function to be simpler
  const refreshTokenBalances = async () => {
    try {
      if (!address) return;
      setIsLoading(true);
      console.log("Fetching owned tokens...");
      const response = await fetch(
        `https://glacier-api.avax.network/v1/chains/43114/addresses/${address}/balances:listErc721?pageSize=10&contractAddress=${distortionsContractAddress}`
      );
      const data = await response.json();
      console.log("Owned tokens fetched:", data);
      if (data && data.erc721TokenBalances) {
        // Only extract token IDs without trying to fetch metadata
        const tokenBalances: NftBalance[] = data.erc721TokenBalances.map((item: any) => ({
          tokenId: item.tokenId
        }));
        setOwnedTokens(tokenBalances);
      } else {
        setOwnedTokens([]);
      }
    } catch (error) {
      console.error('Failed to fetch owned NFTs:', error);
      setOwnedTokens([]);
    } finally {
      setIsLoading(false);
    }
  };

  const loadStakedTokens = async () => {
    try {
      if (!stakingContract || !address) {
        console.log("LoadStakedTokens: Missing requirements:", {
          hasContract: !!stakingContract,
          hasAddress: !!address
        });
        return;
      }
      setIsLoading(true);
      console.log("Fetching staked tokens...");
      const stakeInfo = await stakingContract.call("getStakeInfo", [address]);
      console.log("Staked tokens fetched:", stakeInfo);
      if (Array.isArray(stakeInfo) && stakeInfo.length > 0) {
        setStakedTokens(stakeInfo[0]);
      } else {
        console.warn("Unexpected stakeInfo format:", stakeInfo);
        setStakedTokens([]);
      }
    } catch (error) {
      console.error("Failed to load staked tokens:", error);
      setStakedTokens([]);
    } finally {
      setIsLoading(false);
    }
  };

  const loadClaimableRewards = async () => {
    try {
      if (!stakingContract || !address) {
        console.log("LoadClaimableRewards: Missing requirements:", {
          hasContract: !!stakingContract,
          hasAddress: !!address
        });
        return;
      }
      setIsLoading(true);
      console.log("Fetching claimable rewards...");
      const stakeInfo = await stakingContract.call("getStakeInfo", [address]);
      console.log("Claimable rewards fetched:", stakeInfo);
      if (Array.isArray(stakeInfo) && stakeInfo.length > 1) {
        setClaimableRewards(stakeInfo[1]);
      } else {
        console.warn("Unexpected stakeInfo format:", stakeInfo);
        setClaimableRewards(undefined);
      }
    } catch (error) {
      console.error("Error loading claimable rewards:", error);
      setClaimableRewards(undefined);
    } finally {
      setIsLoading(false);
    }
  };

  const loadFees = async () => {
    try {
        if (!prizeDistributionContract) {
            console.log("Prize distribution contract not initialized");
            return;
        }
        console.log("Loading fees...");
        const auraFeeValue = await prizeDistributionContract.call("aFee");
        const avaxFeeValue = await prizeDistributionContract.call("vFee");
        console.log("Aura fee loaded:", auraFeeValue?.toString());
        console.log("Avax fee loaded:", avaxFeeValue?.toString());
        setAuraFee(auraFeeValue);
        setAvaxFee(avaxFeeValue);
    } catch (error) {
        console.error("Failed to load fees:", error);
    }
  };

  const checkExplorationStatus = async () => {
    try {
      console.log("Checking exploration status...");
      const playerData = await prizeDistributionContract?.call("playerData", [address]);
      
      if (playerData && !playerData.isZero()) {
        console.log("Player data (exploration block):", playerData.toString());
        setHasExplored(true);
        
        // Get current block number
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const currentBlock = await provider.getBlockNumber();
        const explorationBlockNumber = playerData.toNumber();
        const confirmations = Math.max(0, currentBlock - explorationBlockNumber);
        
        console.log(`Current block: ${currentBlock}, Exploration block: ${explorationBlockNumber}, Confirmations: ${confirmations}`);
        
        // Set exploration block and confirmations
        setExplorationBlock(explorationBlockNumber);
        setBlockConfirmations(confirmations);
        
        if (confirmations >= 3) {
          setExplorationState('ready-to-claim');
        } else {
          setExplorationState('exploring');
          setIsMonitoring(true);
        }
      } else {
        console.log("No active exploration, checking AURA approval...");
        setHasExplored(false);
        setExplorationState('none');
        
        // Check AURA approval
        if (auraContract && auraFee && address) {
          const currentAllowance = await auraContract.call("allowance", [address, prizeDistributionContractAddress]);
          const allowanceValue = Array.isArray(currentAllowance) ? currentAllowance[0] : currentAllowance;
          setNeedsApproval(allowanceValue.lt(auraFee));
        }
      }
    } catch (error) {
      console.error("Failed to check exploration status:", error);
    }
  };

  const handleStake = async (id: string) => {
    try {
      if (!address || !stakingContract) return;
      setIsLoading(true);
      console.log("Staking token with ID:", id);
      const isApproved = await distortionsContract?.call("isApprovedForAll", [address, stakingContractAddress]);
      if (!isApproved) {
        console.log("Setting approval for staking contract...");
        showNotification("Approving NFT collection for staking...", "info");
        await distortionsContract?.call("setApprovalForAll", [stakingContractAddress, true]);
      }
      showNotification("Staking your NFT...", "info");
      const transaction = await stakingContract.call("stake", [[id]]);
      if (transaction) {
        console.log("Token staked successfully, refreshing data...");
        showNotification("NFT staked successfully!", "success");
        await refreshTokenBalances();
        await loadStakedTokens();
        await loadClaimableRewards();
      }
    } catch (error) {
      console.error('Stake error:', error);
      showNotification("Failed to stake NFT. Please try again.", "error");
    } finally {
      setIsLoading(false);
    }
  };

  const handleUnstake = async (id: string) => {
    try {
      if (!stakingContract) return;
      setIsLoading(true);
      console.log("Unstaking token with ID:", id);
      showNotification("Unstaking your NFT...", "info");
      const transaction = await stakingContract.call("withdraw", [[id]]);
      if (transaction) {
        console.log("Token unstaked successfully, refreshing data...");
        showNotification("NFT unstaked successfully!", "success");
        await refreshTokenBalances();
        await loadStakedTokens();
        await loadClaimableRewards();
      }
    } catch (error) {
      console.error('Unstake error:', error);
      showNotification("Failed to unstake NFT. Please try again.", "error");
    } finally {
      setIsLoading(false);
    }
  };

  // Add useEffect for block monitoring
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    let cleanup = false;

    const monitorBlocks = async () => {
      if (!isMonitoring || cleanup || !explorationBlock) return;

      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const currentBlock = await provider.getBlockNumber();
        const confirmations = currentBlock - explorationBlock;
        
        console.log(`Current block: ${currentBlock}, Exploration block: ${explorationBlock}, Confirmations: ${confirmations}`);
        setBlockConfirmations(confirmations);
        
        if (confirmations >= 3) {
          try {
            console.log("Initiating claim...");
            const tx = await prizeDistributionContract?.call("claim");
            console.log("Claim transaction:", tx);

            const receipt = tx.receipt as TransactionReceipt;
            console.log("Transaction receipt:", receipt);

            // Look for the PrizeGiven event in the logs
            const prizeGivenLog = receipt.logs?.find((log: Log) => {
              // The event signature for PrizeGiven
              const eventSignature = "PrizeGiven(address,uint8,address,uint256,uint32,uint16,uint256)";
              const eventTopic = ethers.utils.id(eventSignature);
              return log.topics[0] === eventTopic;
            });

            if (prizeGivenLog) {
              console.log("Found PrizeGiven log:", prizeGivenLog);
              
              // Decode the event data
              const iface = new ethers.utils.Interface([
                "event PrizeGiven(address indexed player, uint8 pType, address token, uint256 amt, uint32 tid, uint16 qty, uint256 idx)"
              ]);
              
              const decodedLog = iface.parseLog(prizeGivenLog);
              console.log("Decoded log:", decodedLog);

              const eventData: PrizeGivenEvent = {
                player: decodedLog.args.player,
                pType: decodedLog.args.pType,
                token: decodedLog.args.token,
                amt: decodedLog.args.amt.toString(),
                tid: decodedLog.args.tid,
                qty: decodedLog.args.qty,
                idx: decodedLog.args.idx
              };
              
              console.log("Parsed event data:", eventData);
              
              const { videoName, displayName } = getPrizeDetails(
                eventData.pType,
                eventData.token,
                eventData.amt,
                eventData.qty
              );

              console.log("Final prize selection:", { videoName, displayName });

              if (videoName && displayName) {
                setCurrentPrizeName(displayName);
                setCurrentVideo(`/videos/${videoName}.mp4`);
                setIsPlayingVideo(true);
              }
            }

            // Reset states
            setExplorationState('none');
            setNeedsApproval(true);
            setAuraAllowance(BigNumber.from(0));
            
            await refreshAllData();
          } catch (error) {
            console.error("Automatic claim failed:", error);
            setExplorationState('ready-to-claim');
          }
          setIsLoading(false);
          setIsMonitoring(false);
          return;
        }
        
        if (!cleanup) {
          timeoutId = setTimeout(monitorBlocks, 1000);
        }
      } catch (error) {
        console.error("Error monitoring blocks:", error);
        setIsMonitoring(false);
        setIsLoading(false);
      }
    };

    if (isMonitoring) {
      monitorBlocks();
      setTimeout(() => setIsMonitoring(false), 30000);
    }

    return () => {
      cleanup = true;
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [isMonitoring, explorationBlock, prizeDistributionContract]);

  // Update handleExploreOrClaim
  const handleExploreOrClaim = async () => {
    try {
      setErrorMessage(null);
      if (!address || !prizeDistributionContract || !auraContract) {
        console.log("Missing address or contract instances.");
        return;
      }

      if (explorationState !== 'ready-to-claim') {
        console.log("Exploring with AVAX fee:", avaxFee?.toString());
        showNotification("Starting exploration of The City...", "info");

        try {
          await prizeDistributionContract.call("explore", [], { 
            value: avaxFee
          });
          
          setIsLoading(true);
          setExplorationState('exploring');
          
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const currentBlock = await provider.getBlockNumber();
          setExplorationBlock(currentBlock);
          setBlockConfirmations(0);
          setIsMonitoring(true);
          
          showNotification("Exploration started! Waiting for confirmations...", "success");
          await refreshAllData();
        } catch (error: any) {
          console.error("Exploration failed:", error);
          showNotification("Exploration failed. Please try again.", "error");
          setIsMonitoring(false);
          setIsLoading(false);
          setExplorationState('none');
          return;
        }
      } else {
        showNotification("Claiming your rewards...", "info");
        // Existing claim logic...
      }

      // After successful exploration, set emergency claim as available
      setEmergencyClaimAvailable(true);
      
    } catch (error: any) {
      console.error("Error during explore or claim:", error);
      showNotification("Transaction failed. Please try again.", "error");
      setIsMonitoring(false);
      setIsLoading(false);
    }
  };

  // Add new emergency claim function
  const handleEmergencyClaim = async () => {
    try {
      setIsLoading(true);
      setErrorMessage(null);
      
      if (!prizeDistributionContract) {
        setErrorMessage("Contract not initialized");
        return;
      }

      try {
        // Create a promise that rejects after 30 seconds
        const timeoutPromise = new Promise((_, reject) => {
          setTimeout(() => reject(new Error("timeout")), 30000);
        });

        // Create the claim promise
        const claimPromise = prizeDistributionContract.call("claim");

        // Race between timeout and claim
        const tx = await Promise.race([claimPromise, timeoutPromise]);
        await tx.wait();
        
        // Reset states after successful claim
        setEmergencyClaimAvailable(false);
        await refreshAllData();
        
      } catch (txError: any) {
        console.error("Transaction error:", txError);
        
        // Handle specific error cases
        if (txError.message?.includes("Player did not explore")) {
          setErrorMessage("No prize available to claim");
        } else if (
          txError.message?.includes("timeout") || 
          txError.code === "TIMEOUT" ||
          txError.message?.includes("execution reverted")
        ) {
          setErrorMessage("Request timed out. Please try again");
        } else {
          setErrorMessage("Emergency claim failed. Please try again");
        }

        // Prevent the error from propagating up
        return;
      }
      
    } catch (error: any) {
      console.error("Emergency claim failed:", error);
      setErrorMessage("Unexpected error. Please try again");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAvaxBalance = async () => {
    if (!address) return;
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const balance = await provider.getBalance(address);
      setAvaxBalance(ethers.utils.formatEther(balance));
    } catch (error) {
      console.error("Error fetching AVAX balance:", error);
    }
  };

  const handleApproval = async () => {
    try {
      setErrorMessage(null);
      setIsLoading(true);
      
      if (!auraContract || !auraFee || !address) {
        throw new Error("Contract, fee, or address not initialized");
      }

      showNotification("Approving AURA tokens for exploration...", "info");
      await auraContract.call(
        "approve",
        [prizeDistributionContractAddress, auraFee]
      );

      // Wait briefly for the transaction to be processed
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // Check the new allowance
      const newAllowance = await auraContract.call(
        "allowance", 
        [address, prizeDistributionContractAddress]
      );
      
      const allowanceValue = Array.isArray(newAllowance) ? newAllowance[0] : newAllowance;
      setAuraAllowance(allowanceValue);
      
      // Update needsApproval based on the new allowance
      setNeedsApproval(allowanceValue.lt(auraFee));
      showNotification("AURA tokens approved successfully!", "success");
      
    } catch (error: any) {
      console.error("Approval error:", error);
      if (error.message?.includes("rejected") || 
          error.message?.includes("denied") ||
          error.message?.includes("cancelled")) {
        setErrorMessage("Approval cancelled.");
        showNotification("Approval cancelled by user.", "error");
      } else {
        setErrorMessage("Approval failed. Please try again.");
        showNotification("Failed to approve AURA tokens. Please try again.", "error");
      }
    } finally {
      setIsLoading(false);
    }
  };

  // Add these addresses as constants
  const TOTAL_SUPPLY = ethers.utils.parseUnits("1000000000", 18); // 1 billion with 18 decimals
  const STAKING_WALLET = "0x51697170F78136c8d143B0013Cf5B229aDe70757";
  const TREASURY_WALLET = "0x34Bf7e538BAA555053eC81704923A473ACF4b2e2";
  const DEAD_WALLET = "0x000000000000000000000000000000000000dEaD";

  // Add The City address as a constant
  const CITY_WALLET = "0xd3106799fD6D4bA51AD58A4e77a68fC10f47FF5c";

  // Add this helper function
  const formatLargeNumber = (num: number): string => {
    // First round to 2 decimal places to avoid floating point issues
    const roundedNum = Math.round(num * 100) / 100;
    
    if (roundedNum >= 1000000000) {
      return (roundedNum / 1000000000).toFixed(2) + 'B';
    } else if (roundedNum >= 1000000) {
      return (roundedNum / 1000000).toFixed(2) + 'M';
    } else if (roundedNum >= 1000) {
      return (roundedNum / 1000).toFixed(2) + 'K';
    }
    // For numbers less than 1000, round to 2 decimal places
    return roundedNum.toFixed(2);
  };

  // Update the calculateSupplies function
  const calculateSupplies = async () => {
    if (!auraContract) return;

    try {
      const [stakingBalance, treasuryBalance, burnedBalance, cityBalance] = await Promise.all([
        auraContract.call("balanceOf", [STAKING_WALLET]),
        auraContract.call("balanceOf", [TREASURY_WALLET]),
        auraContract.call("balanceOf", [DEAD_WALLET]),
        auraContract.call("balanceOf", [CITY_WALLET])
      ]);

      const excludedBalance = (Array.isArray(stakingBalance) ? stakingBalance[0] : stakingBalance)
        .add(Array.isArray(treasuryBalance) ? treasuryBalance[0] : treasuryBalance)
        .add(Array.isArray(burnedBalance) ? burnedBalance[0] : burnedBalance)
        .add(Array.isArray(cityBalance) ? cityBalance[0] : cityBalance);

      const circulating = TOTAL_SUPPLY.sub(excludedBalance);
      
      // Format with K/M/B
      const circulatingNum = Number(ethers.utils.formatUnits(Array.isArray(circulating) ? circulating[0] : circulating, 18));
      const burnedNum = Number(ethers.utils.formatUnits(Array.isArray(burnedBalance) ? burnedBalance[0] : burnedBalance, 18));
      
      setCirculatingSupply(formatLargeNumber(circulatingNum));
      setBurnedTokens(formatLargeNumber(burnedNum));
    } catch (error) {
      console.error("Error calculating supplies:", error);
      setCirculatingSupply("Error");
      setBurnedTokens("Error");
    }
  };

  // Update the claimable rewards display
  const formatClaimableRewards = () => {
    if (!claimableRewards) return "0.00";
    const rewards = Number(ethers.utils.formatUnits(claimableRewards, 18));
    return formatLargeNumber(rewards);
  };

  // Fetch fees from the contract
  useEffect(() => {
    const fetchFees = async () => {
      if (!prizeDistributionContract) return;

      try {
        const auraFeeValue = await prizeDistributionContract.call("aFee");
        const avaxFeeValue = await prizeDistributionContract.call("vFee");
        setAuraFee(auraFeeValue);
        setAvaxFee(avaxFeeValue);
      } catch (error) {
        console.error("Error fetching fees:", error);
      }
    };

    fetchFees();
  }, [prizeDistributionContract]);

  // Helper function to map prize types and determine the correct video and display name
  const getPrizeDetails = (prizeType: number, tokenAddress: string, amount: string, quantity: number = 0): { 
    videoName: string; 
    displayName: PrizeName;
  } => {
    // Convert token address and amount to lowercase for comparison
    const tokenLower = tokenAddress.toLowerCase();
    const amountBN = BigNumber.from(amount);
    
    // Debug logging
    console.log("Prize Details Input:", {
      prizeType,
      tokenAddress: tokenLower,
      amount: amountBN.toString(),
      quantity,
      isAura: tokenLower === AURA_TOKEN.toLowerCase(),
      isHeresy: tokenLower === HERESY_TOKEN.toLowerCase(),
    });
    
    // First check for specific prize types
    if (prizeType === 0) { // PT_NONE
      return {
        videoName: "MawsCurse",
        displayName: "Maw's Curse"
      };
    }
    
    if (prizeType === 1) { // PT_ERC20
      // Handle WAVAX -> "Greed's Gift"
      if (tokenLower === "0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7") {
        return {
          videoName: "GreedsGift",
          displayName: "Greed's Gift"
        };
      }

      // Handle AURA -> "Siren's Call" or "Wrath's Intervention" 
      if (tokenLower === AURA_TOKEN.toLowerCase()) {
        // "Siren's Call" is 500 AURA = 500000000000000000000
        if (amountBN.eq(BigNumber.from("500000000000000000000"))) {
          return {
            videoName: "SirensCall",
            displayName: "Siren's Call"
          };
        }
        // "Wrath's Intervention" is 750 AURA = 750000000000000000000
        if (amountBN.eq(BigNumber.from("750000000000000000000"))) {
          return {
            videoName: "WrathsIntervention",
            displayName: "Wrath's Intervention"
          };
        }

        // If the token is AURA but doesn't match known amounts
        return {
          videoName: "HereticsHonour",
          displayName: "Heretic's Honour"
        };
      }

      // Default for other ERC20 tokens
      return {
        videoName: "MawsCurse",
        displayName: "Maw's Curse"
      };
    }
    
    // Handle random NFT cases
    if (prizeType === 4) { // PT_RANDOM_ERC721
      if (quantity === 2) {
        return {
          videoName: "SakurasEmbrace",
          displayName: "Sakura's Embrace"
        };
      }
      // Quantity is not 2, return TheCore
      return {
        videoName: "TheCore",
        displayName: "Spoils of The Core"
      };
    }
    
    if (prizeType === 5) { // PT_RANDOM_ERC1155 - Using the same video but with a slight name difference
      return {
        videoName: "TheCore", // Same video file but referenced differently to avoid TS duplicate property error
        displayName: "Spoils of The Core"
      };
    }
    
    if (prizeType === 6) { // PT_PCT_RANDOM_ERC20
      return {
        videoName: "HereticsHonour",
        displayName: "Heretic's Honour"
      };
    }
    
    // Default fallback for any other prize type
    return {
      videoName: "MawsCurse",
      displayName: "Maw's Curse"
    };
  };

  // Add a function to handle reward claiming with animation
  const handleClaimRewards = async () => {
    try {
      if (!stakingContract || !claimableRewards) return;
      
      setIsLoading(true);
      showNotification("Claiming AURA rewards...", "info");
      
      const formattedRewards = formatClaimableRewards();
      
      await stakingContract.call("claimRewards");
      
      // Show reward animation
      setRewardAnimation({
        isVisible: true,
        amount: `+${formattedRewards} AURA`
      });
      
      showNotification("Rewards claimed successfully!", "success");
      
      // Refresh data after animation
      await loadClaimableRewards();
      
    } catch (error) {
      console.error("Failed to claim rewards:", error);
      showNotification("Failed to claim rewards. Please try again.", "error");
    } finally {
      setIsLoading(false);
    }
  };

  const hideRewardAnimation = () => {
    setRewardAnimation({
      isVisible: false,
      amount: "0"
    });
  };

  // Add an effect to update the video volume when it changes
  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.volume = videoVolume;
    }
  }, [videoVolume, videoRef, isPlayingVideo]);

  const [isExploreHovered, setIsExploreHovered] = useState(false);

  return (
    <>
      <style>{shakeAnimation}</style>
      <style>{`
        /* Social Links styles - moved to top section */
        .links-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 20px 0;
          width: 100%;
        }
        
        .icon-links {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          gap: 15px;
          margin-bottom: 15px;
        }
        
        .text-links {
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
          gap: 12px;
        }
        
        .icon-link {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          background: rgba(0, 0, 0, 0.4);
          display: flex;
          align-items: center;
          justify-content: center;
          color: #fff;
          font-size: 1.4em;
          border: 2px solid rgba(0, 255, 255, 0.3);
          transition: all 0.3s ease;
        }
        
        .icon-link:hover {
          border-color: rgba(0, 255, 255, 0.8);
          background: rgba(0, 20, 40, 0.6);
          transform: translateY(-3px);
          box-shadow: 0 0 15px rgba(0, 255, 255, 0.5);
        }
        
        .x-logo {
          font-family: sans-serif;
          font-weight: bold;
          font-size: 1.3em;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        
        .text-link {
          color: #fff;
          text-decoration: none;
          padding: 5px 12px;
          border: 1px solid rgba(255, 255, 255, 0.2);
          border-radius: 4px;
          transition: all 0.3s ease;
          font-size: 0.9em;
        }
        
        .text-link:hover {
          background: rgba(255, 255, 255, 0.1);
          border-color: rgba(255, 255, 255, 0.4);
        }
        
        .developer-credit {
          margin: 15px 0;
          text-align: center;
          display: block;
          color: #fff;
        }
        
        @media (max-width: 768px) {
          .icon-links {
            gap: 10px;
          }
          
          .icon-link {
            width: 40px;
            height: 40px;
            font-size: 1.2em;
          }
          
          .text-links {
            gap: 8px;
          }
        }
      `}</style>
      <div className="scanline-overlay" />
      <LoadingIndicator isActive={isLoading} />
      {explorationState === 'exploring' && (
        <ProgressBar current={Math.min(blockConfirmations, 3)} max={3} label="Exploration Progress" />
      )}
      <Notification 
        message={notification.message}
        type={notification.type}
        isVisible={notification.isVisible}
        onClose={hideNotification}
      />
      <RewardAnimation 
        amount={rewardAnimation.amount}
        isVisible={rewardAnimation.isVisible}
        onComplete={hideRewardAnimation}
      />
      
      {/* Top section with original background */}
      <div className="main bg1" style={{ 
        minHeight: '60vh', 
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '30px 0',
        position: 'relative'
      }}>
        {/* Title in the middle */}
        <div style={{ 
          width: '100%',
          flex: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
          <h1 className="fa head" style={{ 
            margin: 0,
            fontSize: '54px',
            textAlign: 'center'
          }}>
            Enter The City
            {userDisplayName && <span>, {userDisplayName}</span>}
          </h1>
        </div>
        
        {/* Icons at the bottom */}
        <div style={{ width: '100%', marginTop: '60px' }}>
          <div className="icon-links" style={{
            display: 'flex',
            justifyContent: 'center',
            gap: '15px'
          }}>
            <a href="https://x.com/TheAtriumDAO" target="_blank" rel="noopener noreferrer" className="icon-link">
              <span className="x-logo">𝕏</span>
            </a>
            <a href="https://discord.gg/r7j5QgbfCB" target="_blank" rel="noopener noreferrer" className="icon-link">
              <DiscordIcon />
            </a>
            <a href="/roleclaim" className="icon-link">
              <UserIcon />
            </a>
            <a href="https://analogdistortions.com" target="_blank" rel="noopener noreferrer" className="icon-link">
              <ExternalLinkIcon />
            </a>
            <Link to="/blubstaking" className="icon-link">
              <CoinsIcon />
            </Link>
            <a href="https://bullet.exchange/collections/66fee59823bad0b9b3584bd2" target="_blank" rel="noopener noreferrer" className="icon-link">
              <ExchangeIcon />
            </a>
          </div>
        </div>
        
        {!isWalletConnected && (
          <ConnectWallet 
            className="ccbutton" 
            style={{ 
              fontFamily: 'Doctor Glitch',
              fontWeight: 'normal',
              fontStyle: 'oblique',
              color: 'white',
              margin: '20px auto 0'
            }} 
            theme={darkTheme({ fontFamily: "Hacked, sans-serif", colors: { modalBg: "#002020", accentText: "cyan" } })}
          />
        )}
      </div>
      
      {/* Animated divider between sections */}
      <div className="section-divider-animated"></div>
      
      {/* Secondary section with new background */}
      <div className="bg-secondary">
        <div className="colbox">
          {isWalletConnected && (
            <div className={`colbox bg2 ${
              ownedTokens && ownedTokens.length === 0 && hasTokenStaked 
                ? 'staked-only-view' 
                : ownedTokens && ownedTokens.length > 0 && !hasTokenStaked 
                  ? 'unstaked-only-view' 
                  : ''
            }`}>
              {!(ownedTokens && ownedTokens.length === 0 && hasTokenStaked) && (
                <>
                  <h2 className="fa topmar">Stake Your Analog Distortions</h2>
                  {!(ownedTokens && ownedTokens.length > 0 && !hasTokenStaked) && <div className="section-divider"></div>}
                  {!(ownedTokens && ownedTokens.length > 0 && !hasTokenStaked) && <div className="rowboxGAP widthboost"></div>}
                </>
              )}
              <div className="rowboxGAP">
                <div className="rmar">
                  <div className="colbox">
                    {/* Owned NFTs Display */}
                    {ownedTokens && ownedTokens.length > 0 ? (
                      ownedTokens.length <= 2 ? (
                        <div className="large-nft-display">
                          {ownedTokens.map((nft) => (
                            <UnstakedNFTCard
                              key={nft.tokenId}
                              tokenId={parseInt(nft.tokenId)}
                              onStake={handleStake}
                              distortionsContractAddress={distortionsContractAddress}
                              stakingContractAddress={stakingContractAddress}
                              isLargeDisplay={true}
                            />
                          ))}
                        </div>
                      ) : (
                        <div className="nft-grid">
                          {ownedTokens.map((nft) => (
                            <UnstakedNFTCard
                              key={nft.tokenId}
                              tokenId={parseInt(nft.tokenId)}
                              onStake={handleStake}
                              distortionsContractAddress={distortionsContractAddress}
                              stakingContractAddress={stakingContractAddress}
                            />
                          ))}
                        </div>
                      )
                    ) : (
                      <div className="empty-container">
                        <p className="fb no-unstaked-message">No unstaked NFTs found</p>
                      </div>
                    )}
                    
                    {/* Staked NFTs Display */}
                    {hasTokenStaked && (
                      <div className={ownedTokens && ownedTokens.length === 0 ? 'staked-only-container' : ''}>
                        <h3 className={`fa ${ownedTokens && ownedTokens.length === 0 ? 'no-botmar staked-only-title white-text' : 'botmar'}`}>{ownedTokens && ownedTokens.length === 0 ? 'Staked Analog Distortions' : 'Staked Distortions:'}</h3>
                        {stakedTokens.length <= 2 ? (
                          <div className="large-nft-display">
                            {stakedTokens.map((stakedToken) => (
                              <NFTCard
                                tokenId={stakedToken.toNumber()}
                                key={stakedToken.toString()}
                                onUnstake={handleUnstake}
                                isLargeDisplay={true}
                              />
                            ))}
                          </div>
                        ) : (
                          <div className="nft-grid">
                            {stakedTokens.map((stakedToken) => (
                              <NFTCard
                                tokenId={stakedToken.toNumber()}
                                key={stakedToken.toString()}
                                onUnstake={handleUnstake}
                              />
                            ))}
                          </div>
                        )}
                      </div>
                    )}
                    
                    <div className="colbox center-column">
                      {/* Add AURA PENDING display above Claim Rewards button */}
                      <div className="balance-item fb claim-pending-display">
                        <b>{!claimableRewards ? "Loading..." : formatClaimableRewards()}</b>
                        <b>AURA Pending</b>
                      </div>
                      <Web3Button
                        className="ccbutton claim-rewards-button"
                        action={handleClaimRewards}
                        contractAddress={stakingContractAddress}
                      >
                        <h3 className="fb">Claim Rewards</h3>
                      </Web3Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {isWalletConnected && (
            <div className={`colbox aura-stats-section`} style={{ marginTop: "10px", marginBottom: "10px" }}>
              <h2 className="fa">Aura Stats:</h2>
              <div className="balance-row padtop padbot">
                <div className="balance-item fb">
                  <b>{circulatingSupply}</b>
                  <b>Circulating Aura</b>
                </div>
                <div className="balance-item fb">
                  <b>{burnedTokens}</b>
                  <b>AURA Burned</b>
                </div>
              </div>
            </div>
          )}
          {isWalletConnected && (
            <div className={`colbox bg3 botmar ${isExploreHovered ? 'shake' : ''}`}>
              <div className="rowbox widthboost">
                {explorationState === 'exploring' ? (
                  <div className="explorebutton exploring">
                    <div className="exploration-animation"></div>
                    <h4 className="fb">
                      Exploring The City<br></br>
                      {Math.min(blockConfirmations, 3)} of 3 blocks confirmed<br></br>
                      Please wait...
                    </h4>
                  </div>
                ) : (
                  <div 
                    onMouseEnter={() => setIsExploreHovered(true)}
                    onMouseLeave={() => setIsExploreHovered(false)}
                  >
                    <Web3Button
                      className={`explorebutton ${needsApproval ? 'approve-mode' : explorationState === 'ready-to-claim' ? 'claim-mode' : 'explore-mode'}`}
                      contractAddress={needsApproval ? auraContractAddress : prizeDistributionContractAddress}
                      action={explorationState === 'ready-to-claim' 
                        ? handleExploreOrClaim 
                        : needsApproval 
                          ? handleApproval 
                          : handleExploreOrClaim
                      }
                      isDisabled={isLoading}
                    >
                      <div className="explore-button-content">
                        <h1 className="fa bot">
                          {explorationState === 'ready-to-claim' 
                            ? "Claim Rewards" 
                            : needsApproval 
                              ? "Approve to Explore" 
                              : "Explore The City"
                          }
                        </h1>
                      </div>
                    </Web3Button>
                  </div>
                )}
              </div>
              <h2 className="fb topmar gluttony-section">
                <Tooltip text="Pay 50,000 $AURA and 0.015 $AVAX to explore The City and win prizes.">
                  Gluttony's Indulgence<br />50K AURA<br />0.015 AVAX
                </Tooltip>
              </h2>
              
              {/* Move emergency claim button here */}
              <div className="emergency-claim-container">
                <Tooltip text="If the normal claim fails, use this emergency button to claim your rewards.">
                  <button 
                    className="emergency-claim-button"
                    onClick={handleEmergencyClaim}
                    disabled={isLoading || !emergencyClaimAvailable}
                    title="Use only if normal claim fails"
                  >
                    {isLoading ? "⏳" : "⚠️"}
                  </button>
                </Tooltip>
                <div className="emergency-claim-label">
                  <span className="emergency-claim-arrow">←</span>
                  <span className="fb">Emergency Claim</span>
                  {!emergencyClaimAvailable && <span className="emergency-claim-hint">(Available after exploration)</span>}
                </div>
              </div>
              
              {/* Add volume control button and slider */}
              <div className="volume-control-container">
                <button 
                  className="volume-button" 
                  onClick={() => setIsVolumeSliderVisible(!isVolumeSliderVisible)}
                  title="Volume Control"
                >
                  {videoVolume === 0 ? (
                    <span className="volume-icon">🔇</span>
                  ) : videoVolume < 0.5 ? (
                    <span className="volume-icon">🔉</span>
                  ) : (
                    <span className="volume-icon">🔊</span>
                  )}
                </button>
                
                {isVolumeSliderVisible && (
                  <div className="volume-slider-container">
                    <input
                      type="range"
                      min="0"
                      max="1"
                      step="0.01"
                      value={videoVolume}
                      onChange={(e) => setVideoVolume(parseFloat(e.target.value))}
                      className="volume-slider"
                    />
                    <span className="volume-percentage">{Math.round(videoVolume * 100)}%</span>
                  </div>
                )}
              </div>
              
              {errorMessage && (
                <div className="error-message fb">
                  {errorMessage}
                </div>
              )}
              
              {/* Condensed token display */}
              <h4 className="fb holdings-label">YOUR HOLDINGS</h4>
              <div className="compact-token-display">
                <div className="compact-token">
                  <span className="fb">AURA</span>
                  <span className="mini-token-amount">{auraBalance ? formatLargeNumber(Number(auraBalance.displayValue)) : "0.00"}</span>
                </div>
                <div className="compact-token">
                  <span className="fb">AVAX</span>
                  <span className="mini-token-amount">{avaxBalance ? formatLargeNumber(Number(avaxBalance)) : "0.00"}</span>
                </div>
                <div className="compact-token">
                  <span className="fb">HERESY</span>
                  <span className="mini-token-amount">{heresyBalance ? Math.floor(Number(heresyBalance.displayValue)).toLocaleString() : "0"}</span>
                </div>
              </div>
            </div>
          )}
        </div>
        
        {/* Developer credit moved below explore section */}
        <span className="developer-credit fb">
          Developed by <a href="https://x.com/SnapsNoCaps" target="_blank" rel="noopener noreferrer">snaps</a> with 🩵
        </span>
      </div>
      
      {isPlayingVideo && currentVideo && (
        <div className="video-overlay">
          <div className="video-container">
            <h3 className="fa prize-name">{currentPrizeName}</h3>
            <video
              ref={videoRef}
              autoPlay
              onLoadStart={() => console.log("Video loading started:", currentVideo)}
              onCanPlay={() => console.log("Video can play:", currentVideo)}
              onPlay={() => console.log("Video started playing")}
              onEnded={async () => {
                console.log("Video playback ended");
                setIsPlayingVideo(false);
                setCurrentVideo(null);
                setCurrentPrizeName(null);
                setExplorationState('none');

                // Instead of force-setting approval to true, just re-check everything
                // which will correctly update the allowance state.
                await checkExplorationStatus();
                await refreshAllData(); 
              }}
              onError={(e) => {
                console.error("Video playback error:", e);
                console.error("Failed video path:", currentVideo);
                // Fallback if video fails to play
                setTimeout(() => {
                  setIsPlayingVideo(false);
                  setCurrentVideo(null);
                  setCurrentPrizeName(null);
                  setExplorationState('none');
                  checkExplorationStatus();
                  setNeedsApproval(true);
                }, 3000);
              }}
              className="prize-video"
            >
              <source src={currentVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      )}
    </>
  );
};

export default App;

// Add these component definitions at the top of your file (near the other imports)
// Custom SVG icon components to replace React Icons
const DiscordIcon: React.FC = () => (
  <svg viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
    <path d="M20.317 4.492c-1.53-.69-3.17-1.2-4.885-1.49a.062.062 0 0 0-.066.031c-.175.31-.37.781-.506 1.127-1.55-.234-3.09-.234-4.61 0-.139-.346-.334-.816-.511-1.127a.064.064 0 0 0-.065-.031c-1.713.29-3.353.8-4.885 1.49a.057.057 0 0 0-.027.022C.533 9.093-.32 13.555.099 17.961a.08.08 0 0 0 .031.055c1.993 1.457 3.922 2.34 5.817 2.928a.067.067 0 0 0 .073-.025c.399-.547.749-1.125 1.051-1.733a.06.06 0 0 0-.033-.084 18.87 18.87 0 0 1-2.685-1.275.06.06 0 0 1-.006-.102c.18-.135.36-.274.533-.413a.064.064 0 0 1 .067-.01c3.391 1.55 7.057 1.55 10.405 0a.064.064 0 0 1 .068.01c.172.14.353.278.534.413a.06.06 0 0 1-.006.102c-.855.5-1.755.922-2.686 1.275a.061.061 0 0 0-.033.084c.307.608.658 1.186 1.05 1.733a.066.066 0 0 0 .074.025c1.9-.588 3.829-1.47 5.822-2.928a.068.068 0 0 0 .032-.055c.5-5.176-.838-9.599-3.549-13.447a.056.056 0 0 0-.026-.022zM8.02 15.278c-1.144 0-2.089-1.055-2.089-2.345 0-1.29.918-2.344 2.089-2.344 1.179 0 2.103 1.064 2.088 2.344 0 1.29-.918 2.345-2.088 2.345zm7.707 0c-1.144 0-2.088-1.055-2.088-2.345 0-1.29.918-2.344 2.088-2.344 1.178 0 2.102 1.064 2.088 2.344 0 1.29-.91 2.345-2.088 2.345z" />
  </svg>
);

const UserIcon: React.FC = () => (
  <svg viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
    <path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" />
  </svg>
);

const ExternalLinkIcon: React.FC = () => (
  <svg viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
    <path d="M18 19H6c-.55 0-1-.45-1-1V6c0-.55.45-1 1-1h5c.55 0 1-.45 1-1s-.45-1-1-1H5c-1.11 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2v-6c0-.55-.45-1-1-1s-1 .45-1 1v5c0 .55-.45 1-1 1zM14 4c0 .55.45 1 1 1h2.59l-9.13 9.13c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L19 6.41V9c0 .55.45 1 1 1s1-.45 1-1V4c0-.55-.45-1-1-1h-5c-.55 0-1 .45-1 1z" />
  </svg>
);

const CoinsIcon: React.FC = () => (
  <svg viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
    <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1.41 16.09V20h-2.67v-1.93c-1.71-.36-3.16-1.46-3.27-3.4h1.96c.1 1.05.82 1.87 2.65 1.87 1.96 0 2.4-.98 2.4-1.59 0-.83-.44-1.61-2.67-2.14-2.48-.6-4.18-1.62-4.18-3.67 0-1.72 1.39-2.84 3.11-3.21V4h2.67v1.95c1.86.45 2.79 1.86 2.85 3.39H14.3c-.05-1.11-.64-1.87-2.22-1.87-1.5 0-2.4.68-2.4 1.64 0 .84.65 1.39 2.67 1.91s4.18 1.39 4.18 3.91c-.01 1.83-1.38 2.83-3.12 3.16z" />
  </svg>
);

const ExchangeIcon: React.FC = () => (
  <svg viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
    <path d="M6.99 11L3 15l3.99 4v-3H14v-2H6.99v-3zM21 9l-3.99-4v3H10v2h7.01v3L21 9z" />
  </svg>
);

