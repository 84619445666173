import {
  ThirdwebNftMedia,
  useContract,
  Web3Button,
} from "@thirdweb-dev/react";
import type { FC } from "react";
import { useState, useEffect } from "react";
import "../styles/Home.css";

interface UnstakedNFTCardProps {
  tokenId: number;
  onStake: (id: string) => Promise<void>;
  distortionsContractAddress: string;
  stakingContractAddress: string;
  isLargeDisplay?: boolean;
}

const UnstakedNFTCard: FC<UnstakedNFTCardProps> = ({ 
  tokenId, 
  onStake, 
  distortionsContractAddress,
  stakingContractAddress,
  isLargeDisplay = false 
}) => {
  const { contract } = useContract(distortionsContractAddress, "nft-drop");
  const [metadata, setMetadata] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchMetadata() {
      if (!contract) return;
      setIsLoading(true);

      const ipfsGateways = [
        "https://gateway.ipfs.io/ipfs/",
        "https://nftstorage.link/ipfs/",
        "https://cloudflare-ipfs.com/ipfs/",
        "https://ipfs.io/ipfs/",
        "https://dweb.link/ipfs/",
        "https://gateway.pinata.cloud/ipfs/",
      ];

      try {
        // Fetch token URI from the contract
        let tokenUriResult = await contract.call("tokenURI", [tokenId]);

        // Check if the result is an array and extract the value
        let tokenUri: string;
        if (Array.isArray(tokenUriResult)) {
          tokenUri = tokenUriResult[0];
        } else {
          tokenUri = tokenUriResult;
        }

        // Ensure tokenUri is a string
        if (typeof tokenUri !== "string") {
          console.error("tokenUri is not a string:", tokenUri);
          return;
        }

        // Extract CID and path from tokenUri
        const cidAndPath = tokenUri.replace("ipfs://", "");

        // Fetch metadata from multiple gateways
        let fetchedMetadata = null;
        let gatewayUsed = "";

        for (const gateway of ipfsGateways) {
          const url = gateway + cidAndPath;
          try {
            const response = await fetch(url);
            if (response.ok) {
              fetchedMetadata = await response.json();
              gatewayUsed = gateway;
              console.log(`Successfully fetched metadata from ${gateway}`);
              break;
            }
          } catch (err) {
            console.error(`Failed to fetch from ${url}:`, err);
          }
        }

        if (!fetchedMetadata) {
          throw new Error("Failed to fetch metadata from all gateways");
        }

        // Handle images that are also on IPFS
        if (fetchedMetadata.image && fetchedMetadata.image.startsWith("ipfs://")) {
          fetchedMetadata.image = fetchedMetadata.image.replace(
            "ipfs://",
            gatewayUsed
          );
        }

        // Set the metadata state
        setMetadata({
          ...fetchedMetadata,
          id: tokenId.toString(),
          uri: tokenUri,
        });
      } catch (error) {
        console.error(`Failed to fetch metadata for token ${tokenId}:`, error);
      } finally {
        // Set loading to false regardless of success or failure
        setIsLoading(false);
      }
    }

    fetchMetadata();
  }, [contract, tokenId]);

  return (
    <>
      {(metadata || isLoading) && (
        <div className={`nftbox ${isLargeDisplay ? 'large-card' : ''}`}>
          <div className="nft-animation-wrapper">
            {isLoading ? (
              <div className="nft-loading-placeholder">
                <div className="nft-spinner"></div>
                <div className="nft-fallback-text">Loading #{tokenId}</div>
              </div>
            ) : (
              <ThirdwebNftMedia 
                metadata={metadata} 
                className="nftmedia" 
                style={{ 
                  objectFit: "contain",
                  width: '100%',
                  height: 'auto'
                }}
              />
            )}
            <div className="nft-glow-overlay"></div>
          </div>
          <h2 className="fa text br sidepadding">{isLoading ? "Loading..." : `"${metadata.name || `#${tokenId}`}"`}</h2>
          <h4 className="fb text br sidepadding">Token {tokenId}</h4>
          <div className="stake-status">
            <span className="status-indicator unstaked"></span>
            <span className="fb status-text">Available to Stake</span>
          </div>
          <Web3Button
            action={() => onStake(tokenId.toString())}
            contractAddress={stakingContractAddress}
            className="stakebutton br sidemarginbm"
          >
            <p className="fb">Stake for Aura</p>
          </Web3Button>
        </div>
      )}
    </>
  );
};

export default UnstakedNFTCard; 